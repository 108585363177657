@import "../../assets/common/tools.scss";

:root {
  --primary: #006bf3;
  --primary-gradient: linear-gradient(180deg, #0073fa 0%, #005fce 100%);
  --gray: #7c90a8;
  --white: #fff;
  --menu-height-mobile: 80px;
  --menu-width-desk: 5rem;
  .app-layout--menu-expanded {
    --menu-width-desk: 13rem;
  }
  .app-menu--hidden,
  .app-menu--hidden .app-layout--menu-expanded {
    --menu-width-desk: 0;
  }
}

.app-menu {
  &--branding--icon--square {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    width: 2.75rem;
    height: 2.75rem;
    box-shadow:
      0px 16px 56px 0px rgba(17, 17, 26, 0.1),
      0px 8px 24px 0px rgba(17, 17, 26, 0.1),
      0px 4px 16px 0px rgba(17, 17, 26, 0.1);
  }
  &--mobile {
    position: fixed;
    left: 0;
    width: 100%;
    padding: 1rem;
    bottom: 0;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom, 0px));
    background-color: #fff;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    transform: translate3d(0, 0, 0);
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    .sheet-modal-present & {
      // note, don't animate menu when modal is present
      display: none;
    }
    @media (max-width: 320px) {
      padding: 1rem 0.5rem;
    }
    @include lg-up() {
      display: none;
    }
    .app-menu--mobile--hidden & {
      display: none;
    }

    &--item {
      text-align: center;
      display: block;
      &--icon {
        display: inline-block;
      }
      &--label {
        font-size: 0.875rem;
        font-weight: bold;
      }
      &--label,
      &--icon {
        color: var(--gray);
        .app-menu--item--active & {
          color: var(--primary);
        }
      }
    }
  }
  &--desktop {
    color: var(--white);
    @include menu-transition(width);
    position: absolute;
    inset: 0;
    right: auto;
    background: var(--primary-gradient);
    display: none;
    width: var(--menu-width-desk);
    overflow: hidden;
    @include lg-up() {
      display: block;
    }
    .app-menu--branding {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      column-gap: 0.8rem;
      padding: 2.2rem 1rem 1.5rem 1rem;
      display: flex;
      margin-bottom: 1.75rem;
      &--logo {
        padding-top: 0.3rem;
      }
    }
    .app-menu--item {
      display: flex;
      align-items: center;
      column-gap: 1.6rem;
      height: 3.125rem;
      color: var(--white);
      opacity: 0.5;
      margin-bottom: 1rem;
      padding: 0 0 0 1.8rem;
      position: relative;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      &--active {
        opacity: 1;
        background: rgba(255, 255, 255, 0.1);
        &:after {
          content: "";
          width: 3px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background-color: #fff;
        }
      }
      &--label {
        font-weight: bold;
      }
    }

    .app-menu--branding--logo--inner,
    .app-menu--item--label {
      @include menu-transition(transform, opacity);
      transform: translate3d(-1rem, 0, 0);
      opacity: 0;
      .app-layout--menu-expanded & {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
  &--toggle {
    display: flex;
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    height: 1.75rem;
    width: 1.75rem;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border-radius: 0.31rem;
    margin: 2rem 0 0 1.5rem;
    @include menu-transition(transform);
    transform: rotate(180deg);
    .app-layout--menu-expanded & {
      transform: rotate(0deg);
    }
  }
}
