.dayroute--driver-select {
  font-weight: bold;
  & > div,
  select {
    width: 100%;
  }
  select {
    border: none;
    box-shadow: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
.dayroute--date-select {
  border: none;
  font-family: inherit;
  width: 280px;
  min-height: 350px;
  font-size: inherit;
  .react-calendar {
    &__navigation button {
      min-width: 28px;
      &:disabled {
        opacity: 0.5;
        background-color: transparent;
      }
      &:active,
      &:hover {
        background-color: transparent;
      }
    }
    &__navigation__label {
      order: 1;
      text-align: left;
      padding-left: 0.8rem;
    }
    &__navigation__label__labelText {
      display: inline-block;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &__navigation__next-button,
    &__navigation__prev-button {
      color: white;
      position: relative;
      &:after {
        position: absolute;
        left: 50%;
        margin-left: -0.35rem;
        content: "";
        display: block;
        width: 0.7rem;
        height: 0.7rem;
        border-width: 0 0 1px 1px;
        border-style: solid;
        border-color: black;
        transform: rotate(-45deg);
      }
      &:before {
        position: absolute;
        left: 50%;
        margin-left: -0.5px;
        content: "";
        display: block;
        width: 1px;
        height: 1.2rem;
        background-color: black;
      }
    }
    &__navigation__next-button {
      order: 2;
      &:after {
        bottom: 0.8rem;
        transform: rotate(-45deg);
      }
    }
    &__navigation__prev-button {
      order: 3;
      &:after {
        top: 0.8rem;
        transform: rotate(135deg);
      }
    }
    &__month-view__days__day--weekend:not(.react-calendar__tile:disabled):not(.react-calendar__tile--active):not(
        .react-calendar__month-view__days__day--neighboringMonth
      ) {
      color: inherit;
    }
    &__navigation__prev2-button,
    &__navigation__next2-button {
      display: none;
    }
    &__tile {
      border-radius: 0.625rem;
      font-size: inherit;
      font-weight: bold;
      border: 1px solid transparent;
    }
    &__month-view__weekdays {
      font-size: inherit;
    }
    &__tile--now:not(.react-calendar__tile--active) {
      background-color: transparent;
      border: 1px solid rgb(171, 171, 171);
      color: inherit;
    }
    &__tile:disabled,
    &__month-view__days__day--neighboringMonth:disabled,
    &__decade-view__years__year--neighboringDecade:disabled,
    &__century-view__decades__decade--neighboringCentury:disabled {
      background-color: transparent;
    }
    &__year-view .react-calendar__tile,
    &__decade-view .react-calendar__tile,
    &__century-view .react-calendar__tile {
      padding: 1.2em 0.5em;
    }
  }
}
