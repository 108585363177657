%hideText {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

%slickPosition {
  position: absolute;
  z-index: 10;
}
.gallery-trigger:after {
  content: "";
  position: absolute;
  z-index: 5;
  inset: 0;
  box-shadow: inset 0 -30px 20px -12px #000;
}

.slick-slider:after,
.slick-slider:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 7rem;
  z-index: 9;
}
.slick-slider:before {
  top: 0;
  box-shadow: inset 0 60px 30px -20px #000;
}
.slick-slider:after {
  bottom: 0;
  box-shadow: inset 0 -60px 30px -20px #000;
}

.slick-arrow {
  @extend %hideText;
  @extend %slickPosition;
  display: none !important;
  top: 50%;
  margin-top: -50px;
  width: 15px;
  height: 15px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  &.slick-prev {
    left: 1rem;
    transform: rotate(-135deg);
  }
  &.slick-next {
    right: 1rem;
    transform: rotate(45deg);
  }
  &.slick-disabled {
    opacity: 0.5;
    cursor: default;
  }
  @media (width >= 1024px) {
    display: block !important;
    .slick-no-arrows & {
      display: none !important;
    }
  }
}

.slick-dots {
  @extend %slickPosition;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 1rem;
  li {
    display: inline-block;
    margin: 0.6rem;
    button {
      @extend %hideText;
      display: block;
      background-color: #fff;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 999rem;
      opacity: 0.5;
    }
    &.slick-active {
      button {
        opacity: 1;
        cursor: default;
      }
    }
  }
}
