@import "../../assets/common/tools";

/**
* Ion modal needs this to z-position correctly
*/
.modal-default {
  position: absolute;
}

.sheet-backdrop {
  perspective: 150px;
  @include sm-up() {
    perspective: 300px;
  }
  @include md-up() {
    perspective: 450px;
  }
  @include lg-up() {
    perspective: 600px;
  }
  @include xl-up() {
    perspective: 750px;
  }
  @include xxl-up() {
    perspective: 900px;
  }
}
