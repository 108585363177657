.timeline {
  position: relative;
  padding-left: 16px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0.8em;
    bottom: 2.5em;
    border-left: 4px solid var(--primary);
  }
  &-a,
  &-b {
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      top: 0.5em;
      left: -20px;
      width: 0.675em;
      height: 0.675em;
      border: 4px solid var(--primary);
      border-radius: 20px;
    }
  }
  &-a {
    &:after {
      background-color: white;
    }
  }
  &-b {
    &:after {
      background-color: var(--primary);
    }
  }
}
