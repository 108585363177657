@import "../assets/common/tools.scss";

body {
  min-height: 100vh;
}

.bg-primary-match-menu {
  background: rgb(0 115 250 / 1);
  @include lg-up() {
    background: var(--primary-gradient);
  }
  &--mobile {
    background: rgb(0 115 250 / 1);
    @include lg-up() {
      background: white;
    }
  }
}

.app-page-wrapper {
  position: absolute;
  inset: 0;
}

/* Somehow when navigating the first page (hidden) has a higher z-index */
.ion-page-hidden {
  display: none;
}

.app-layout {
  // Make text selectable
  -webkit-user-select: auto;
  user-select: auto;
  /* for pages without any overflow - ensure that the minimum height is ONLY AS BIG as the screen */
  position: absolute;
  inset: 0;
  padding-left: 0;
  @include lg-up() {
    transition: padding-left 0.2s ease-in-out;
    padding-left: var(--menu-width-desk);
  }
}

// Start Safe area styling
// Safe area is about the notches, navigation bars, status bars and dynamic islands of this world.
// This should also incorporate our bottom menu
// Simulate safe-area by hardcode padding here
.p-safe-area {
  padding-top: env(safe-area-inset-top, 0px);
  padding-right: env(safe-area-inset-right, 0px);
  padding-left: env(safe-area-inset-left, 0px);
  padding-bottom: calc(5rem + env(safe-area-inset-bottom, 0px));
  @include lg-down() {
    .app-menu--mobile--hidden & {
      padding-bottom: env(safe-area-inset-bottom, 0px);
    }
  }
  @include lg-up() {
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
}

.pt-safe-area {
  padding-top: env(safe-area-inset-top, 0px);
}
.pt-8-safe-area {
  padding-top: calc(2rem + env(safe-area-inset-top, 0px));
}
.pb-safe-area {
  padding-bottom: env(safe-area-inset-bottom, 0px);
}

// Paints the status bar (behind dynamic island)
.status-bar-style {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: env(safe-area-inset-top, 0px);
}
// End safe area styling

// specific bg color
.bg-color-light-blue {
  background-color: #ecf5ff;
}
.\!bg-color-light-blue {
  background-color: #ecf5ff !important;
}
