body.overlay-active {
  overflow: hidden;
}
.overlay-container {
  position: fixed;
  top: env(safe-area-inset-top, 0px);
  right: env(safe-area-inset-right, 0px);
  bottom: env(safe-area-inset-bottom, 0px);
  left: env(safe-area-inset-left, 0px);
  transition: transform 250ms cubic-bezier(0.7, 0, 0.4, 1);
  transition-delay: 0;
  transform: translate3d(0, 100%, 0);
}
@media screen and (min-width: 768px) {
  .overlay-container {
    left: auto;
    max-width: 600px;
    transform: translate3d(120%, 0, 0);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.overlay-active .overlay-container {
  transform: translate3d(0, 0, 0);
  transition-delay: 250ms;
}

.overlay-backdrop {
  position: fixed;
  top: env(safe-area-inset-top, 0px);
  right: env(safe-area-inset-right, 0px);
  bottom: env(safe-area-inset-bottom, 0px);
  left: env(safe-area-inset-left, 0px);
  visibility: hidden;
  transition: all 250ms ease;
  transition-delay: 150ms;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.overlay-active .overlay-backdrop {
  visibility: visible;
  opacity: 1;
  transition-delay: 0;
}
