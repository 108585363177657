/* NOTE: no need to add '@tailwind base' here because it is already handled by CSS import from @brenger/react */
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
}
/* On iOS only add padding to top, so that the user could exit the chat app proparly. */
[data-platform="ios"] #fc_frame.h-open-container,
[data-platform="ios"] #fc_frame.fc-widget-small.h-open-container {
  padding-top: 15px;
}

.dialog-container {
  position: fixed;
  top: env(safe-area-inset-top, 0px);
  right: env(safe-area-inset-right, 0px);
  bottom: env(safe-area-inset-bottom, 0px);
  left: env(safe-area-inset-left, 0px);
}

.maps-wrapper {
  padding-bottom: 46.83%;
}

.message-link-underline a {
  text-decoration: underline;
}

/* Tailwind seems to have no support for this trick, this hides the spinner arrows from a number input */
.hide-spinner[type="number"]::-webkit-inner-spin-button,
.hide-spinner[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .maps-wrapper {
    padding-bottom: 31.33%;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
