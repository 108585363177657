@mixin sm-up {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin xxl-up {
  @media (min-width: 1536px) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: 639px) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin xl-down {
  @media (max-width: 1279px) {
    @content;
  }
}

@mixin xxl-down {
  @media (max-width: 1535px) {
    @content;
  }
}

@mixin menu-transition($property...) {
  transition-property: $property;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
